<script>
  import { onMount } from "svelte";
  import { auth } from "../lib/stores/auth";
  import { get } from "svelte/store";

  let activeTab = "reagents";

  const reagents = [
    {
      type: "Crystals",
      name: "Crystal A",
      description: "A beautiful crystal used in various spells.",
      image: "crystal_a.jpg",
      recipes: ["Spell of Light", "Teleportation Rune"],
    },
    {
      type: "Crystals",
      name: "Crystal B",
      description: "A rare crystal with healing properties.",
      image: "crystal_b.jpg",
      recipes: ["Healing Potion", "Revival Elixir"],
    },
    {
      type: "Ore",
      name: "Iron Ore",
      description: "Raw iron ore mined from deep underground.",
      image: "iron_ore.jpg",
      recipes: ["Iron Sword", "Steel Armor"],
    },
    {
      type: "Ore",
      name: "Gold Ore",
      description: "A precious ore used in jewelry crafting.",
      image: "gold_ore.jpg",
      recipes: ["Golden Ring", "Royal Crown"],
    },
    {
      type: "Plants",
      name: "Herb A",
      description: "A common herb used in many potions.",
      image: "herb_a.jpg",
      recipes: ["Healing Salve", "Stamina Potion"],
    },
    {
      type: "Plants",
      name: "Herb B",
      description: "A rare herb known for its magical properties.",
      image: "herb_b.jpg",
      recipes: ["Mana Potion", "Ethereal Essence"],
    },
  ];

  const craftingTypes = ["Alchemy", "Artificery", "Cooking"];

  const recipes = {
    Alchemy: [
      {
        name: "Elixir of Life",
        description: "Restores health and vitality.",
        image: "elixir.jpg",
      },
      {
        name: "Invisibility Potion",
        description: "Grants invisibility for a short duration.",
        image: "invisibility_potion.jpg",
      },
    ],
    Artificery: [
      {
        name: "Magic Wand",
        description: "Channels arcane energy for spellcasting.",
        image: "magic_wand.jpg",
      },
      {
        name: "Enchanted Amulet",
        description: "Boosts magical abilities when worn.",
        image: "enchanted_amulet.jpg",
      },
    ],
    Cooking: [
      {
        name: "Dragon Steak",
        description: "A hearty meal for adventurers.",
        image: "dragon_steak.jpg",
      },
      {
        name: "Mystic Brew",
        description: "A mysterious drink with magical properties.",
        image: "mystic_brew.jpg",
      },
    ],
  };

  function switchTab(tab) {
    activeTab = tab;
  }

  onMount(() => {
    //
  });
</script>

<slot>
  <div class="page-container">
    <div class="character-creation-container">
      <div class="tab-buttons">
        <button
          class={activeTab === "reagents" ? "active" : ""}
          on:click={() => switchTab("reagents")}>Reagents</button
        >
        <button
          class={activeTab === "crafting" ? "active" : ""}
          on:click={() => switchTab("crafting")}>Crafting Recipes</button
        >
        <button
          class={activeTab === "rules" ? "active" : ""}
          on:click={() => switchTab("rules")}>Rules</button
        >
      </div>

      <div class="tab-content">
        {#if activeTab === "reagents"}
          <div class="nav-buttons">
            {#each ["Crystals", "Ore", "Plants"] as type}
              <button>{type}</button>
            {/each}
          </div>

          <div class="reagents-container">
            {#each reagents as reagent}
              <div class="reagent-card">
                <div class="reagent-card-content">
                  <h3>{reagent.name}</h3>
                  <p>{reagent.description}</p>
                  <p>Recipes: {reagent.recipes.join(", ")}</p>
                </div>
                <img src={reagent.image} alt={reagent.name} />
              </div>
            {/each}
          </div>
        {/if}

        {#if activeTab === "crafting"}
          <div class="nav-buttons">
            {#each craftingTypes as type}
              <button>{type}</button>
            {/each}
          </div>

          <div class="crafting-recipes-container">
            {#each craftingTypes as type}
              {#each recipes[type] as recipe}
                <div class="recipe-card">
                  <h3>{recipe.name}</h3>
                  <p>{recipe.description}</p>
                  <img src={recipe.image} alt={recipe.name} />
                </div>
              {/each}
            {/each}
          </div>
        {/if}

        {#if activeTab === "rules"}
          <div class="rules-container">
            <h2>Crafting Rules</h2>
            <p>Here are the rules for crafting:</p>
            <ul>
              <li>Rule 1: Always use high-quality ingredients.</li>
              <li>Rule 2: Follow the recipe instructions precisely.</li>
              <li>Rule 3: Use the correct crafting tools.</li>
              <li>
                Rule 4: Store reagents properly to maintain their potency.
              </li>
            </ul>
          </div>
        {/if}
      </div>
    </div>
  </div>
</slot>

<style>
  * {
    box-sizing: border-box;
  }

  .page-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 80px;
  }

  .character-creation-container {
    width: 80%;
    font-family: "Arial", sans-serif;
    color: #f0f0f0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 20px;
  }

  .tab-buttons {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .tab-buttons button {
    background-color: #444;
    color: #f0f0f0;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    cursor: pointer;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transition: background-color 0.3s;
    flex: 1;
    text-align: center;
    background-color: #654321;
  }

  .tab-buttons button.active {
    background-color: #3b250e;
  }

  .tab-buttons button:hover {
    background-color: #654321a5;
  }

  .tab-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    padding: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    background-color: #270344;
  }

  .nav-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }

  .nav-buttons button {
    background-color: #654321;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 20px;
    cursor: pointer;
  }

  .reagents-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    width: 100%;
  }

  .reagent-card,
  .recipe-card {
    display: flex;
    background-color: #444;
    border-radius: 8px;
    padding: 10px;
    align-items: center;
    color: #f0f0f0;
    background-color: #654321;
    box-shadow: 0 0 2px rgb(0, 0, 0);
  }

  .reagent-card img,
  .recipe-card img {
    width: 100px;
    height: 100px;
    margin-left: auto;
    border: 6px solid #654321;
    border-radius: 10px;
    background-color: #333;
  }

  .reagent-card-content,
  .recipe-card-content {
    flex: 1;
    margin-right: 10px;
  }

  .crafting-recipes-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .rules-container {
    width: 100%;
    padding: 20px;
    background-color: #654321;
    border-radius: 8px;
    color: #f0f0f0;
    background-color: #654321;
    box-shadow: 0 0 2px rgb(0, 0, 0);
  }
</style>
