<script>
  import { onMount } from "svelte";
  import { push } from "svelte-spa-router";
  import { getValidAccessToken } from "../lib/stores/auth";

  let authState;
  let characters = [];

  onMount(async () => {
    authState = get(auth);
    if (!authState.isAuthenticated) {
      push("/signin");
    } else {
      try {
        characters = await fetchUserCharacters();
      } catch (error) {
        console.error("Error fetching characters:", error);
      }
    }
  });

  async function fetchUserCharacters() {
    const token = await getValidAccessToken();
    const response = await fetch(`/api/characters`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  }

  function goToCharacterSheet(characterId) {
    push(`/character/${characterId}`);
  }
</script>

<div class="profile-container">
  <div class="header">Adventurer's License</div>
  <div class="profile-info">
    <div class="info-column-left">
      <label for="username">Username: </label>
      <p id="username">{authState?.user ? authState.user.username : ""}</p>
      <label for="email">Email: </label>
      <p id="email">{authState?.user ? authState.user.email : ""}</p>
    </div>
    <div class="info-column-right">
      <label for="exp">EXP: </label>
      <p id="exp">{authState?.user ? authState.user.exp : ""}</p>
      <label for="level">Level: </label>
      <p id="level">{authState?.user ? authState.user.level : ""}</p>
    </div>
  </div>
  <div class="center-column">
    <label for="class">CLASS: </label>
    <p id="class">Adventurer</p>
  </div>

  <div class="character-list">
    <h2>Your Characters</h2>
    {#if characters.length > 0}
      <div class="character-grid">
        {#each characters as character (character.id)}
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <div
            class="character-card"
            on:click={() => goToCharacterSheet(character.id)}
          >
            <h3>{character.name}</h3>
            <p>Class: {character.class}</p>
            <p>Level: {character.level}</p>
          </div>
        {/each}
      </div>
    {:else}
      <p>No characters created yet.</p>
    {/if}
  </div>
</div>

<style>
  .profile-container {
    width: 75%;
    margin: 100px auto;
    padding: 20px;
    background-color: #d7b594;
    border: 20px solid #bc9760;
    min-height: 350px;
    margin-top: 150px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }

  .header {
    font-size: 36px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .profile-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .center-column {
    margin-top: 50px;
  }

  .info-column-left {
    width: 65%;
    text-align: left;
    margin-left: 50px;
  }

  .info-column-right {
    width: 25%;
    text-align: left;
  }

  label {
    font-size: 24px;
    font-weight: bold;
  }

  p {
    margin: 5px 0;
    margin-bottom: 30px;
    font-size: 18px;
  }

  .character-list {
    margin-top: 50px;
    width: 100%;
  }

  .character-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }

  .character-card {
    background-color: #f0e4d7;
    padding: 20px;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.2s ease;
  }

  .character-card:hover {
    transform: translateY(-5px);
  }

  .character-card h3 {
    margin-bottom: 10px;
    font-size: 24px;
  }

  .character-card p {
    margin: 5px 0;
    font-size: 16px;
  }
</style>
