<script>
  import { writable } from "svelte/store";
  import { onMount } from "svelte";
  import { getValidAccessToken } from "../lib/stores/auth";

  let currentView = writable("origins");

  // Character Info
  let characterName = "";

  let selectedCulture = 0;
  let selectedHeritage1 = 0;
  let selectedHeritage2 = 0;
  let selectedTraining = 0;
  let selectedAlignment = 0;

  let selectedCultureName = "";
  let selectedHeritage1Name = "";
  let selectedHeritage2Name = "";
  let selectedTrainingName = "";
  let selectedAlignmentName = "";

  // Stats
  let characterStrength = 0;
  let characterDexterity = 0;
  let characterConsitution = 0;
  let characterIntelligence = 0;
  let characterWisdom = 0;
  let characterCharisma = 0;

  // Lists
  let cultureList = [];
  let heritageList = [];
  let trainingList = [];
  let alignmentList = [];

  $: characterSheetFilledIn =
    characterName &&
    selectedCulture &&
    selectedHeritage1 &&
    selectedHeritage2 &&
    selectedTraining &&
    selectedAlignment &&
    characterStrength &&
    characterDexterity &&
    characterConsitution &&
    characterIntelligence &&
    characterWisdom &&
    characterCharisma;

  // Fetch data from API on component mount
  onMount(async () => {
    await fetchCultures();
    await fetchHeritages();
    await fetchTrainings();
    await fetchAlignments();
  });

  // Fetch cultures
  async function fetchCultures() {
    const response = await fetch("/api/cultures");
    const data = await response.json();
    cultureList = data;
  }

  // Fetch heritages
  async function fetchHeritages() {
    const response = await fetch("/api/heritages");
    const data = await response.json();
    heritageList = data;
  }

  // Fetch trainings
  async function fetchTrainings() {
    const response = await fetch("/api/trainings");
    const data = await response.json();
    trainingList = data;
  }

  // Fetch alignments
  async function fetchAlignments() {
    const response = await fetch("/api/alignments");
    const data = await response.json();
    trainingList = data;
  }

  function selectCulture(data) {
    selectedCulture = data.id;
    selectedCultureName = data.name;
  }

  function selectHeritage1(data) {
    selectedHeritage1 = data.id;
    selectedHeritage1Name = data.name;
  }

  function selectHeritage2(data) {
    selectedHeritage2 = data.id;
    selectedHeritage2Name = data.name;
  }

  function selectTraining(data) {
    selectedTraining = data.id;
    selectedTrainingName = data.name;
  }

  function selectAlignment(data) {
    selectedAlignment = data.id;
    selectedAlignmentName = data.name;
  }

  // Set the current view
  function setView(view) {
    currentView.set(view);
  }

  function validCharacter() {
    if (
      characterName &&
      selectedCulture &&
      selectedHeritage1 &&
      selectedHeritage2 &&
      selectedTraining &&
      selectedAlignment &&
      characterStrength &&
      characterDexterity &&
      characterConsitution &&
      characterIntelligence &&
      characterWisdom &&
      characterCharisma
    ) {
      return true;
    }
    return false;
  }

  async function createNewCharacter() {
    if (validCharacter()) {
      return;
    }
    const characterData = {
      name: characterName,
      culture: selectedCulture,
      heritage_1: selectedHeritage1,
      heritage_2: selectedHeritage2,
      training: selectedTraining,
      alignment: selectedAlignment,
      strength: characterStrength,
      dexterity: characterDexterity,
      constitution: characterConsitution,
      intelligence: characterIntelligence,
      wisdom: characterWisdom,
      charisma: characterCharisma,
    };

    const token = await getValidAccessToken();
    try {
      const response = await fetch("/api/characters/post/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(characterData),
      });
      if (response.ok) {
        console.log("Character created successfully!");
      } else {
        console.error("Failed to create character.");
      }
    } catch (error) {
      console.log("E: ", error);
    }
  }
</script>

<slot>
  <div class="page-container">
    <div class="character-creation-container">
      <div class="sidebar">
        <button
          class="sidebar-button {$currentView === 'origins'
            ? 'activate'
            : 'deactivate'}"
          on:click={() => setView("origins")}>Origins</button
        >
        <button
          class="sidebar-button {$currentView === 'training'
            ? 'activate'
            : 'deactivate'}"
          on:click={() => setView("training")}>Power</button
        >
        <button
          class="sidebar-button {$currentView === 'abilities'
            ? 'activate'
            : 'deactivate'}"
          on:click={() => setView("abilities")}>Abilities</button
        >
        <button
          class="sidebar-button {$currentView === 'details'
            ? 'activate'
            : 'deactivate'}"
          on:click={() => setView("details")}>Details</button
        >
      </div>

      {#if $currentView === "abilities"}
        <div>
          <div class="full-view-container">
            <div>
              <h2>Set Abilities</h2>
              <label for="strength">Strength:</label>
              <input
                class="input"
                id="strength"
                type="number"
                bind:value={characterStrength}
              />
              <label for="dexterity">Dexterity:</label>
              <input
                class="input"
                id="dexterity"
                type="number"
                bind:value={characterDexterity}
              />
              <label for="constitution">Constitution:</label>
              <input
                class="input"
                id="constitution"
                type="number"
                bind:value={characterConsitution}
              />
            </div>
            <div class="marginTop">
              <label for="intelligence">Intelligence:</label>
              <input
                class="input"
                id="intelligence"
                type="number"
                bind:value={characterIntelligence}
              />
              <label for="wisdom">Wisdom:</label>
              <input
                class="input"
                id="wisdom"
                type="number"
                bind:value={characterWisdom}
              />
              <label for="charisma">Charisma:</label>
              <input
                class="input"
                id="charisma"
                type="number"
                bind:value={characterCharisma}
              />
            </div>
          </div>
          <button
            class="character-button left-margin"
            disabled={!characterSheetFilledIn}
            on:click={() => createNewCharacter()}>Create Character</button
          >
        </div>
      {:else}
        <div class="view-container">
          {#if $currentView === "details"}
            <div class="home-view">
              <label for="name">Character Name:</label>
              <input id="name" type="text" bind:value={characterName} />
            </div>
          {/if}

          {#if $currentView === "origins"}
            <div class="culture-view">
              <h2>Select Culture</h2>
              <div class="default-buttons">
                {#each cultureList as culture}
                  <button
                    class:selected={culture.name === selectedCulture}
                    on:click={() => selectCulture(culture)}
                  >
                    {culture.name}
                  </button>
                {/each}
              </div>
              <h2>Select First Heritage</h2>
              <div class="default-buttons">
                {#each heritageList as heritage}
                  <button
                    class:selected={heritage.name === selectedHeritage1}
                    on:click={() => selectHeritage1(heritage)}
                  >
                    {heritage.name}
                  </button>
                {/each}
              </div>
              <h2>Select Second Heritage</h2>
              <div class="default-buttons">
                {#each heritageList as heritage}
                  <button
                    class:selected={heritage.name === selectedHeritage2}
                    on:click={() => selectHeritage2(heritage)}
                  >
                    {heritage.name}
                  </button>
                {/each}
              </div>
            </div>
          {/if}

          {#if $currentView === "training"}
            <div class="training-view">
              <h2>Select Training</h2>
              <div class="default-buttons">
                {#each trainingList as training}
                  <button
                    class:selected={training.name === selectedTraining}
                    on:click={() => selectTraining(training)}
                  >
                    {training.name}
                  </button>
                {/each}
              </div>
              <h2>Select Alignment</h2>
              <div class="default-buttons">
                {#each alignmentList as alignment}
                  <button
                    class:selected={alignment.name === selectedAlignment}
                    on:click={() => selectTraining(alignment)}
                  >
                    {alignment.name}
                  </button>
                {/each}
              </div>
            </div>
          {/if}

          <button
            class="character-button"
            disabled={!characterSheetFilledIn}
            on:click={() => createNewCharacter()}
          >
            Create Character
          </button>
        </div>

        <div class="view-container right-panel">
          {#if $currentView === "details"}
            <div class="selected-options">
              <h2>Selected Options</h2>

              <div
                class="option-item {selectedCultureName
                  ? ''
                  : 'missing-option'}"
              >
                <span class="option-label">Culture:</span>
                <span class="option-value"
                  >{selectedCultureName || "Missing"}</span
                >
              </div>

              <div
                class="option-item {selectedHeritage1Name
                  ? ''
                  : 'missing-option'}"
              >
                <span class="option-label">Heritage 1:</span>
                <span class="option-value"
                  >{selectedHeritage1Name || "Missing"}</span
                >
              </div>

              <div
                class="option-item {selectedHeritage2Name
                  ? ''
                  : 'missing-option'}"
              >
                <span class="option-label">Heritage 2:</span>
                <span class="option-value"
                  >{selectedHeritage2Name || "Missing"}</span
                >
              </div>

              <div
                class="option-item {selectedTrainingName
                  ? ''
                  : 'missing-option'}"
              >
                <span class="option-label">Training:</span>
                <span class="option-value"
                  >{selectedTrainingName || "Missing"}</span
                >
              </div>

              <div
                class="option-item {selectedAlignmentName
                  ? ''
                  : 'missing-option'}"
              >
                <span class="option-label">Alignment:</span>
                <span class="option-value"
                  >{selectedAlignmentName || "Missing"}</span
                >
              </div>
            </div>
          {/if}

          {#if $currentView === "origins"}
            {#if selectedCulture}
              <div class="default-info">
                <h3>{selectedCultureName}</h3>
                <p>
                  {cultureList.find((s) => s.id === selectedCulture)
                    .description}
                </p>
              </div>
            {/if}
            {#if selectedHeritage1}
              <div class="default-info">
                <h3>{selectedHeritage1Name}</h3>
                <p>
                  {heritageList.find((s) => s.id === selectedHeritage1)
                    .description}
                </p>
              </div>
            {/if}
            {#if selectedHeritage2}
              <div class="default-info">
                <h3>{selectedHeritage2Name}</h3>
                <p>
                  {heritageList.find((s) => s.id === selectedHeritage2)
                    .description}
                </p>
              </div>
            {/if}
          {/if}

          {#if $currentView === "training"}
            {#if selectedTraining}
              <div class="class-info">
                <h3>{selectedTrainingName}</h3>
                <p>
                  {trainingList.find((c) => c.id === selectedTraining)
                    .description}
                </p>
              </div>
            {/if}
          {/if}
        </div>
      {/if}
    </div>
  </div>
</slot>

<style>
  * {
    box-sizing: border-box;
  }

  .page-container {
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    padding: 15px;
    width: 100%;
    height: 95vh;
  }

  .character-creation-container {
    display: flex;
    height: 100vh;
    width: 100%;
    font-family: "Arial", sans-serif;
    color: white;
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    padding: 20px;
    color: white;
    margin-top: 20px;
    width: 250px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  }

  .marginTop {
    margin-top: 48px;
  }

  .left-margin {
    margin-left: 20px;
  }

  .sidebar-button {
    padding: 15px;
    margin: 10px 0;
    background-color: #7c005a;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
  }

  .sidebar-button:hover {
    background-color: #5a0142;
  }

  .activate {
    background-color: #50053c;
  }

  .view-container {
    flex: 1;
    padding: 40px;
    overflow-y: auto;
  }

  .full-view-container {
    flex: 1;
    padding: 40px;
    overflow-y: auto;
    display: flex;
    padding: 10px;
  }

  .right-panel {
    margin-top: 70px;
  }

  .character-button {
    margin-top: 50px;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: #4caf50;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition:
      background-color 0.3s ease,
      opacity 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .character-button:hover {
    background-color: #45a049;
  }

  .character-button:focus {
    outline: 2px solid #2196f3;
    outline-offset: 3px;
  }

  .character-button:disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
    opacity: 0.7;
    box-shadow: none;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  label {
    display: block;
    font-size: 16px;
    margin: 10px;
    margin-top: 20px;
    margin-bottom: 5px;
    font-weight: bold;
  }

  input {
    width: 300px;
    padding: 10px;
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }

  .input {
    padding: 10px;
    margin: 10px;
  }

  .default-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
  }

  .default-buttons button {
    padding: 10px;
    background-color: #7c005a;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }

  .default-buttons button.selected {
    background-color: #480135;
    font-weight: bold;
  }

  .default-buttons button:hover {
    background-color: #54013e;
  }

  .default-info {
    margin-top: 20px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .selected-options {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }

  .selected-options h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
  }

  .option-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    padding: 10px;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #ddd;
    transition: box-shadow 0.3s ease;
  }

  .option-item:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  .option-label {
    font-weight: 600;
    font-size: 16px;
    color: #555;
  }

  .option-value {
    font-weight: 500;
    font-size: 16px;
    color: #333;
  }

  .right-panel {
    margin-left: auto;
    padding: 20px;
  }

  .missing-option {
    border: 2px solid red;
    padding: 5px;
    border-radius: 4px;
  }

  @media (max-width: 768px) {
    .selected-options {
      max-width: 100%;
    }
  }
</style>
