<script>
  import { onMount } from "svelte";
  export let params;

  let storyId;

  $: {
    storyId = params.storyId;
  }

  let story = {};

  function formatDate(dateString) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  }

  onMount(async () => {
    try {
      const response = await fetch(`/api/stories/${storyId}`);
      if (response.ok) {
        const data = await response.json();
        story = data;
      } else {
        console.error("failed to fetch data from the API");
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  });
</script>

<slot>
  <div class="colored-rectangle">
    <h1 class="story-header">{story.title}</h1>
    <div class="story-category-container">
      <h5 class="story-category">{story.tags}</h5>
    </div>
    <p class="story-date">
      {formatDate(story.published_date)} - by {story.author}
    </p>
    <p class="story-text">{@html story.content}</p>
  </div></slot
>

<style>
  .colored-rectangle {
    width: 75%;
    margin: 100px auto;
    padding: 20px;
    background-color: #d7b594;
    border: 20px solid #bc9760;
    min-height: 80%;
    margin-top: 100px;
    border-radius: 15px;
    font-family: "Acme", sans-serif;
  }

  .story-category-container {
    background-color: #ff7a00;
    color: #fff;
    border-radius: 8px;
    text-align: center;
    padding: 2px;
    max-width: 80%;
    max-height: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  .story-category {
    font-size: 12px;
    text-transform: uppercase;
  }

  .story-header,
  .story-date {
    text-align: center;
  }

  .story-header {
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .story-date {
    font-size: 14px;
    color: #777;
    margin-top: 5px;
  }

  .story-text {
    font-size: 16px;
    margin-top: 10px;
  }
</style>
