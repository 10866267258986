<script>
  import Navbar from "../lib/components/Navbar.svelte";
  import Footer from "../lib/components/Footer.svelte";
</script>

<div class="layout">
  <Navbar />
  <slot />
  <Footer />
</div>

<style>
  .layout {
    background-color: #210742;
    margin: 0px;
    padding: 0px;
    background-attachment: fixed;
    background-size: cover;
    z-index: 0;
  }

  .layout::before {
    content: "";
    position: fixed;
    top: -20%;
    right: -20%;
    width: 1000px;
    height: 1000px;
    background-color: #00ffd1;
    border-radius: 50%;
    mix-blend-mode: color-dodge;
    filter: blur(50px);
    opacity: 1%;
    z-index: 0;
    pointer-events: none;
  }

  .layout::after {
    content: "";
    position: fixed;
    bottom: -20%;
    left: -20%;
    width: 1000px;
    height: 1000px;
    background-color: #ffd600;
    border-radius: 50%;
    mix-blend-mode: color-dodge;
    filter: blur(50px);
    opacity: 1%;
    z-index: 0;
    pointer-events: none;
  }
</style>
