<script>
  import { onMount } from "svelte";
  import { push } from "svelte-spa-router";
  import { auth } from "../lib/stores/auth";
  import { get } from "svelte/store";

  let authState;

  onMount(() => {
    authState = get(auth);
    if (!authState.isAuthenticated) {
      push("/signin");
    }
  });
</script>

<div class="profile-container">
  <div class="header">Adventurer's License</div>
  <div class="profile-info">
    <div class="info-column-left">
      <label for="username">Username: </label>
      <p id="username">{authState?.user ? authState.user.username : ""}</p>
      <label for="email">Email: </label>
      <p id="email">{authState?.user ? authState.user.email : ""}</p>
    </div>
    <div class="info-column-right">
      <label for="exp">EXP: </label>
      <p id="exp">{authState?.user ? authState.user.exp : ""}</p>
      <label for="level">Level: </label>
      <p id="level">{authState?.user ? authState.user.level : ""}</p>
    </div>
  </div>
  <div class="center-column">
    <label for="class">CLASS: </label>
    <p id="exp">Adventurer</p>
  </div>
</div>

<style>
  .profile-container {
    width: 75%;
    margin: 100px auto;
    padding: 20px;
    background-color: #d7b594;
    border: 20px solid #bc9760;
    min-height: 350px;
    margin-top: 150px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }

  .header {
    font-size: 36px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .profile-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .center-column {
    margin-top: 50px;
  }

  .info-column-left {
    width: 65%;
    text-align: left;
    margin-left: 50px;
  }

  .info-column-right {
    width: 25%;
    text-align: left;
  }

  label {
    font-size: 24px;
    font-weight: bold;
  }

  p {
    margin: 5px 0;
    margin-bottom: 30px;
    font-size: 18px;
  }
</style>
