import App from './App.svelte';

document.body.style.margin = '0';
document.body.style.padding = '0';
document.body.style.backgroundColor = '#210742';
document.body.style.fontFamily = "Uncial Antiqua";

const app = new App({
	target: document.body,
});

export default app;