<script>
  import { onMount } from "svelte";
  import { auth } from "../lib/stores/auth";
  import { get } from "svelte/store";

  // Data Logic

  // Accordion Logic

  // Editor Logic
  const authState = get(auth);
  const dm = authState.isAuthenticated && authState.user?.dm;
  const username = authState.user?.username;

  //   async function fetchQuests() {
  //     try {
  //       const response = await fetch(`/api/characters`);
  //       if (response.ok) {
  //         const quest_data = await response.json();
  //         event_quests = quest_data.filter((quest) => quest.category === "event");
  //         character_quests = quest_data.filter(
  //           (quest) => quest.category === "character"
  //         );
  //         side_quests = quest_data.filter((quest) => quest.category === "side");
  //         completed_quests = quest_data.filter(
  //           (quest) => quest.category === "complete"
  //         );
  //       } else {
  //         console.error("Failed to fetch data from the API");
  //       }
  //     } catch (error) {
  //       console.error("Error: ", error);
  //     }
  //   }

  import { writable } from "svelte/store";

  let currentView = writable("home");

  // Character Info
  let characterName = "";
  let description = {
    height: "",
    eyeColor: "",
    // Add other descriptive fields as needed
  };

  // Species Info
  let selectedSpecies = "";
  let speciesList = [
    {
      name: "Humans",
      description:
        "Humans are a common race in Eres found across the continent. The only city before the war that lacked a Human population was Highgarden due to their more isolationist outlook. Steamforge has a very small population of humans working alongside the dwarves and gnomes.",
    },
    {
      name: "Elves",
      description:
        "The primary elven city was the twin city of Highgarden and Undergarden, all subraces lived together within the cities but the wood elves often preferred the surrounding forest. However, some elves left these two cities to travel across the continent with the hope of encountering other cultures and breaking the isolationist belief of their race. Following the destruction of the twinned city the elves have now moved across Eres. Originally from the realm of Phantasia along with the Fey, they desired a more controlled environment, less driven by the turbulent emotions they feel and so settled in Eres. They are long lived but when an elf is killed their soul travels to the Afterfey at the end of time, they reflect on their lives before their souls are sent back in time to the moment of their death, an unexplainable phenomenon that only afflicts the Elves and allows them remember their previous lives.",
    },
    {
      name: "Half-elves",
      description:
        "Some of the elves that left Highgarden and Undergarden intermingled with the other races, in particular Humans, and mixed race children were born. Usually born to younger elves between the ages of 18-99, they're recklessness and passion overriding the cultural taboo of producing a child whom they will outlive. As the elf grows older they learn that trysts with other races is often imprudent. The half-elf children tend to live their lives with abandon embracing the nature of their elven parent.",
    },
    {
      name: "Dwarves",
      description:
        "Mountain and Hill Dwarfs typically inhabit the Gaia mountain range and their large fortress city of Steamforge. Some have traveled around Eres in search of rare metals and stones. Steamforge didn’t fall during the war but many families evacuated the besieged city for the safety of Akiba. There are also settlements throughout the Gaia mountain range.",
    },
    {
      name: "Gnomes",
      description:
        "Rock Gnomes also inhabit Steamforge with the Dwarves, specialising in gemstones and fine artisan craftsmanship. The two races work well together, although the Rock Gnomes leave the ruling of the city in the hands of the Dwarven Royal families. Forest Gnomes inhabit the woodlands skirting the bottom of the Gaia mountains.",
    },
    {
      name: "Halflings",
      description:
        "Halflings inhabited the plains of the Aesthma river from the city of Bloodraven to the then provincial town of Akiba. Stonehill and Plainsgate were two major halfling towns before the war. Once the war broke out and Bloodraven was attacked by the Ooze Kaiju the halflings fled from the plain to congregate in Akiba and the surrounding areas. Now they have begun to venture out and reclaim their settlements most notably Stonehill was recently cleared of a Carrion Crawler infestation.",
    },
    {
      name: "Half-Orcs",
      description:
        "Orcs naturally inhabit the cursed swamp near Bloodraven and the forests west of the Gaia mountains, occasionally a human and an orc would marry and Half-Orcs would be born. Green of skin like their orc parents they fought against the tides of Red Orcs that came through the Ragnar Portals and they now live throughout Eres, cohabiting many cities including Akiba.",
    },
    {
      name: "Tieflings",
      description:
        "Tieflings appeared in Eres through ancestral blessings from devils and fiends. The fiends are responsible for maintaining celestial order and dislike when divine laws are broken, so when other Noids informed the gods of divine laws being broken they were blessed and the infernal magic entered the blood to be passed down to future generations. They mainly inhabited the city of Bloodraven and surrounding areas, often shunned by other Noids for fear of being ratted out.",
    },
    {
      name: "Dragonborn",
      description:
        "The most common Dragonborn of Eres inhabited the Salarin Desert and Fire’s Jaw hills, as well as often being nomadic traveling through the sands. Their inherent magic made them some of the original settlers of Salarin City. There are also rumors of nomadic Dragonborn tribes that inhabit the Sunrise Expanse to the far north and a possible crystal city. Their nomadic nature means they have traveled throughout Eres.",
    },
    {
      name: "Goliaths",
      description:
        "Goliaths standing tall and strong grew in the mountains surrounding Bloodraven. Bloodraven’s fight pits were often the home of Goliaths using their enormous strength and physique to dominate and win. There are many different tribes of Goliath that are descended from the different tribes of Giants, including fire, ice, stone and storm.",
    },
    {
      name: "Kenku",
      description:
        "The Kenku mostly live in the Murder Isles which lie between the Fey touched Emerald Wilds and the Akiba plains. Largely untouched during the war, the Bard colleges that are found on each Isle were able to continue educating and partying throughout the war. There are many settlements across the Isles home to the Kenku and there may be other populations around Eres. Originally from the Geni Realm the Kenku moved to Eres a long time ago.",
    },
    {
      name: "Genasi",
      description:
        "The Genasi are extremely rare in Eres and are mostly found in the realm of Geni, an elementally touched realm connected to Eres. On occasion a stray Genasi makes their way across. The exception is the Royal Fire Genasi family that ruled over Salarin before the war.",
    },
    {
      name: "Kobolds",
      description:
        "Kobolds wandered the deserts of Salarin and settled in the city; they have been found throughout Eres with recent settlements in the forest surrounding Highgarden. They are known to worship Dragons as revered ancestors.",
    },
    {
      name: "Firbolg",
      description:
        "Much like Goliaths Firbolg are also descended from Giants. A nomadic people moving through the forests of Eres but there is a gathering place where the Firbolg meet called Camp Wildfire just to the north of the Emerald Wilds in the far east of Eres.",
    },
    {
      name: "Grey Dwarves",
      description:
        "The Duergar love to experiment with magical objects and themselves, augmenting their bodies to achieve new and interesting technology. They live far to the north in the Karndar Mountains and founded the kingdom of Karndar. Rarely do they leave their kingdom but they may adventure forth to discover new technology unknown to them.",
    },
    {
      name: "Deep Gnomes or Svirfneblin",
      description:
        "Living their entire lives underground the Deep Gnomes occupy various maze like caverns found under the mountain ring surrounding the Divinity Barrier. Often trading with both Steamforge and Karndar but largely keeping to themselves and they use magic to hide their presence from others. But like all Gnomes, they have a fondness for gems.",
    },
    {
      name: "Aarakocra",
      description:
        "Cousins of the Kenku, Aarakocra create hatcheries and settlements in some hidden areas of Eres. One such settlement is located in the forests surrounding Highgarden which underwent a tragedy as an undead Purple Worm attacked the hatchery. Like the Kenku they are originally from the Geni Realm but they are much rarer as hardly any settled in Eres and they are not native to Eres.",
    },
    {
      name: "Changelings",
      description:
        "Not much is known about Changelings. Some were used by the War Gods during the Ragnar Campaign, the gods took the souls of warriors from the Divine Plane of Ragnar and had them reborn to prove their honour once more. The gods would alter or remove their memories so they could act as sleeper agents, infiltrating different parts of Eres.",
    },
    {
      name: "Githyanki",
      description:
        "In one of the possible future timelines of Eres, aberrations enslaved all noid races. Thousands of years of psionic influence allowed the Githyanki and Githzerai to evolve. A rebellion formed and they sent back a large group to the present day to fight time travelling Mind Flayers who also travelled back to ensure the aberration timeline comes to pass. The Githyanki work with the Order of Time, established by the Platinum Dragon God, Chronak.",
    },
    {
      name: "Githzerai",
      description:
        "The Githzerai are different to the Githyanki in that they travelled back in time to ensure the Mind Flayers never rise to power, even if that means the Githyanki and Githzerai never evolve. The Chrono Magic they utilised to travel back in time altered their physiology from their Githyanki cousins.",
    },
    {
      name: "Aasimar",
      description:
        "Aasimar are the celestial cousin to Tieflings. Celestial creatures who worked with Noids that gained favour were blessed with celestial magic that entered there blood and was passed down through the generations.",
    },
    {
      name: "Tabaxi",
      description:
        "The Tabaxi are extremely rare in Eres, they naturally inhabit the Realm of the Spiritbluffs, a realm home to many of the Beast-Noid races. Except for the ancient subrace the Wild Saber which are native to the Realm of Savarra.",
    },
    {
      name: "Centaurs",
      description:
        "There is a Centaur tribe that lives in the forests surrounding Highgarden, they originally inhabited the Sunlight Valley but it was overrun during the Ragnar Crusades. However, the tribe is small and they emigrated from the Geni Elemental Realm.",
    },
  ];

  // Class Info
  let selectedClass = "";
  let classList = [
    {
      name: "Barbarian",
      description:
        "A fierce warrior of primitive background who can enter a battle rage.",
    },
    {
      name: "Bard",
      description:
        "An inspiring magician whose power echoes the music of creation.",
    },
    {
      name: "Cleric",
      description:
        "A priestly champion who wields divine magic in service of a higher power.",
    },
    {
      name: "Druid",
      description:
        "A priest of the Old Faith, wielding the powers of nature and adopting animal forms.",
    },
    {
      name: "Fighter",
      description:
        "A master of martial combat, skilled with a variety of weapons and armor.",
    },
    {
      name: "Monk",
      description:
        "A master of martial arts, harnessing the power of the body in pursuit of physical and spiritual perfection.",
    },
    { name: "Paladin", description: "A holy warrior bound to a sacred oath." },
    {
      name: "Ranger",
      description:
        "A warrior who uses martial prowess and nature magic to combat threats on the edges of civilization.",
    },
    {
      name: "Rogue",
      description:
        "A scoundrel who uses stealth and trickery to overcome obstacles and enemies.",
    },
    {
      name: "Sorcerer",
      description:
        "A spellcaster who draws on inherent magic from a gift or bloodline.",
    },
    {
      name: "Warlock",
      description:
        "A wielder of magic that is derived from a bargain with an extraplanar entity.",
    },
    {
      name: "Wizard",
      description:
        "A scholarly magic-user capable of manipulating the structures of reality.",
    },
  ];

  // Background Info
  let selectedBackground = "";
  let backgroundList = [
    { name: "Noble", description: "Born of a royal family." },
    { name: "Commoner", description: "Lived a simple life." },
    { name: "Merchant", description: "Traveled the world trading." },
    // Add other backgrounds as needed
  ];

  // Abilities Info
  let abilities = {
    strength: 0,
    dexterity: 0,
    constitution: 0,
    intelligence: 0,
    wisdom: 0,
    charisma: 0,
  };

  function setView(view) {
    currentView.set(view);
  }

  function selectSpecies(species) {
    selectedSpecies = species;
  }

  function selectClass(cls) {
    selectedClass = cls;
  }

  function selectBackground(bg) {
    selectedBackground = bg;
  }

  onMount(() => {
    //
  });
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<slot>
  <div class="page-container">
    <div class="character-creation-container">
      <div class="sidebar">
        <button class="sidebar-button" on:click={() => setView("home")}
          >Home</button
        >
        <button class="sidebar-button" on:click={() => setView("species")}
          >Species</button
        >
        <button class="sidebar-button" on:click={() => setView("class")}
          >Class</button
        >
        <button class="sidebar-button" on:click={() => setView("background")}
          >Background</button
        >
        <button class="sidebar-button" on:click={() => setView("abilities")}
          >Abilities</button
        >
      </div>

      <div class="view-container">
        {#if $currentView === "home"}
          <div class="home-view">
            <h2>Character Name and Description</h2>
            <label for="name">Character Name:</label>
            <input id="name" type="text" bind:value={characterName} />
            <label for="height">Height:</label>
            <input id="height" type="text" bind:value={description.height} />
            <label for="eyeColor">Eye Color:</label>
            <input
              id="eyeColor"
              type="text"
              bind:value={description.eyeColor}
            />
            <!-- Add other descriptive fields as needed -->
          </div>
        {/if}

        {#if $currentView === "species"}
          <div class="species-view">
            <h2>Select Species</h2>
            <div class="species-buttons">
              {#each speciesList as species}
                <button
                  class:selected={species.name === selectedSpecies}
                  on:click={() => selectSpecies(species.name)}
                >
                  {species.name}
                </button>
              {/each}
            </div>
            {#if selectedSpecies}
              <div class="species-info">
                <h3>{selectedSpecies}</h3>
                <p>
                  {speciesList.find((s) => s.name === selectedSpecies)
                    .description}
                </p>
              </div>
            {/if}
          </div>
        {/if}

        {#if $currentView === "class"}
          <div class="class-view">
            <h2>Select Class</h2>
            <div class="class-buttons">
              {#each classList as cls}
                <button
                  class:selected={cls.name === selectedClass}
                  on:click={() => selectClass(cls.name)}
                >
                  {cls.name}
                </button>
              {/each}
            </div>
            {#if selectedClass}
              <div class="class-info">
                <h3>{selectedClass}</h3>
                <p>
                  {classList.find((c) => c.name === selectedClass).description}
                </p>
              </div>
            {/if}
          </div>
        {/if}

        {#if $currentView === "background"}
          <div class="background-view">
            <h2>Select Background</h2>
            <div class="background-buttons">
              {#each backgroundList as bg}
                <button
                  class:selected={bg.name === selectedBackground}
                  on:click={() => selectBackground(bg.name)}
                >
                  {bg.name}
                </button>
              {/each}
            </div>
            {#if selectedBackground}
              <div class="background-info">
                <h3>{selectedBackground}</h3>
                <p>
                  {backgroundList.find((b) => b.name === selectedBackground)
                    .description}
                </p>
              </div>
            {/if}
          </div>
        {/if}

        {#if $currentView === "abilities"}
          <div class="abilities-view">
            <h2>Set Abilities</h2>
            <label for="strength">Strength:</label>
            <input
              id="strength"
              type="number"
              bind:value={abilities.strength}
            />
            <label for="dexterity">Dexterity:</label>
            <input
              id="dexterity"
              type="number"
              bind:value={abilities.dexterity}
            />
            <label for="constitution">Constitution:</label>
            <input
              id="constitution"
              type="number"
              bind:value={abilities.constitution}
            />
            <label for="intelligence">Intelligence:</label>
            <input
              id="intelligence"
              type="number"
              bind:value={abilities.intelligence}
            />
            <label for="wisdom">Wisdom:</label>
            <input id="wisdom" type="number" bind:value={abilities.wisdom} />
            <label for="charisma">Charisma:</label>
            <input
              id="charisma"
              type="number"
              bind:value={abilities.charisma}
            />
          </div>
        {/if}
      </div>
    </div>
  </div>
</slot>

<style>
  * {
    box-sizing: border-box;
  }

  .page-container {
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    padding: 15px;
    height: 95vh;
  }

  .character-creation-container {
    display: flex;
    height: 100vh;
    font-family: "Arial", sans-serif;
    color: white;
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    padding: 20px;
    color: white;
    width: 250px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  }

  .sidebar-button {
    padding: 15px;
    margin: 10px 0;
    background-color: #7c005a;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
  }

  .sidebar-button:hover {
    background-color: #5a0142;
  }

  .view-container {
    flex: 1;
    padding: 40px;
    overflow-y: auto;
    min-width: 90%;
    max-width: 90%;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  label {
    display: block;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 5px;
    font-weight: bold;
  }

  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }

  .species-buttons,
  .class-buttons,
  .background-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
  }

  .species-buttons button,
  .class-buttons button,
  .background-buttons button {
    padding: 10px;
    background-color: #7c005a;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }

  .species-buttons button.selected,
  .class-buttons button.selected,
  .background-buttons button.selected {
    background-color: #7c005a;
    font-weight: bold;
  }

  .species-buttons button:hover,
  .class-buttons button:hover,
  .background-buttons button:hover {
    background-color: #7c005a;
  }

  .species-info,
  .class-info,
  .background-info {
    margin-top: 20px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
</style>
