<script>
  import { onMount } from "svelte";
  export let params;

  let articleId;

  $: {
    articleId = params.articleId;
  }

  let news_article = {};

  function formatDate(dateString) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  }

  onMount(async () => {
    try {
      const response = await fetch(`/api/news-articles/${articleId}`);
      if (response.ok) {
        const data = await response.json();
        news_article = data;
      } else {
        console.error("failed to fetch data from the API");
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  });
</script>

<slot>
  <div class="colored-rectangle">
    <div class="article-category-container {news_article.category}">
      <h1 class="article-category">{news_article.category}</h1>
    </div>
    <h2 class="article-header">{news_article.title}</h2>
    <p class="article-date">{formatDate(news_article.published_date)}</p>
    <img
      src={news_article.thumbnail}
      alt="ArticleImage"
      class="article-image"
    />
    <p class="article-text">{@html news_article.content}</p>
  </div></slot
>

<style>
  .colored-rectangle {
    width: 75%;
    margin: 100px auto;
    padding: 20px;
    background-color: #d7b594;
    border: 20px solid #bc9760;
    min-height: 80%;
    margin-top: 100px;
    border-radius: 15px;
    font-family: "Acme", sans-serif;
  }

  .article-category-container {
    background-color: #ff7a00;
    color: #fff;
    border-radius: 10px;
    text-align: center;
    padding: 3px;
    max-width: 120px;
    max-height: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  .article-category {
    font-size: 16px;
    text-transform: uppercase;
  }

  .world {
    background-color: rgb(102, 87, 0);
  }

  .lore {
    background-color: rgb(1, 41, 114);
  }

  .games {
    background-color: rgb(0, 71, 0);
  }

  .streams {
    background-color: rgb(91, 91, 91);
  }

  .novels {
    background-color: purple;
  }

  .personal {
    background-color: rgb(123, 0, 0);
  }

  .article-header,
  .article-date {
    text-align: center;
  }

  .article-header {
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .article-date {
    font-size: 14px;
    color: #777;
    margin-top: 5px;
  }

  .article-image {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    max-height: 300px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .article-text {
    font-size: 16px;
    margin-top: 10px;
  }
</style>
