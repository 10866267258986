<script>
  import { auth, setAuthToken } from "../lib/stores/auth";
  import { get } from "svelte/store";
  import { push, link } from "svelte-spa-router";

  let username = "";
  let password = "";
  let rememberMe = false;
  let errorMessage = "";

  async function handleLogin() {
    const response = await fetch("/api/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      const token = data.access;
      const refresh = data.refresh;
      const userProfile = await getUserProfile(token);
      setAuthToken(token, refresh, userProfile);
      get(auth);
      push(`/account`);
    } else {
      errorMessage = "Login failed. Please check your credentials.";
    }
  }

  /**
   * @param {any} token
   */
  async function getUserProfile(token) {
    try {
      const response = await fetch("/api/profile", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const userProfileData = await response.json();
        return userProfileData;
      } else {
        console.error("Failed to fetch user profile data");
      }
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  }
</script>

<div class="signup-container">
  <div class="signup-box">
    <h2>Sign In</h2>
    {#if errorMessage}
      <p class="error-message">{errorMessage}</p>
    {/if}
    <div class="input-container">
      <label for="username">Username</label>
      <input
        id="username"
        type="text"
        name="username"
        autocomplete="username"
        bind:value={username}
        required
      />
    </div>
    <div class="input-container">
      <label for="password">Password</label>
      <input
        id="password"
        type="password"
        name="password"
        autocomplete="current-password"
        bind:value={password}
        required
      />
      <!-- <div class="remember-forgot">
        <div class="remember-me">
          <input
            type="checkbox"
            id="remember"
            name="remember"
            bind:checked={rememberMe}
          />
          <label for="remember">Remember Me</label>
        </div>
        <div class="forgot-password">
          <a href="/password-reset" use:link>Forgot Password</a>
        </div>
      </div> -->
    </div>
    <button class="signup-button" on:click={handleLogin}>Sign In</button>
    <div class="not-member">
      Not a member? <a href="/signup" use:link>Sign Up</a>
    </div>
  </div>
</div>

<style>
  .signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-top: 20px;
    margin-bottom: 50px;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .signup-box {
    background-color: white;
    border-radius: 50px;
    padding: 20px;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    max-width: 100%;
  }

  .signup-box h2 {
    font-size: 36px;
    font-family: "Acme", sans-serif;
  }

  .input-container {
    margin-top: 35px;
    text-align: center;
  }

  .input-container label {
    display: block;
    font-weight: bold;
  }

  .input-container input {
    padding: 14px;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 1px;
    border: 3px solid #000;
    border-radius: 30px;
    background-color: #f3f2f2;
    font-family: "Acme", sans-serif;
    font-size: 16px;
  }

  /*.remember-forgot {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
  }

  .remember-me {
    display: flex;
    align-items: center;
    text-align: left;
    margin-top: 10px;
    margin-left: 100px;
  }

  .remember-me input[type="checkbox"] {
    margin-right: 5px;
  }

  .forgot-password {
    margin-top: 10px;
    margin-right: 100px;
  }

  .forgot-password a {
    text-decoration: none;
    color: #0078d4;
  }*/

  .not-member {
    text-align: center;
    margin-top: 15px;
    margin-right: 0;
  }

  .not-member a {
    text-decoration: none;
    color: #0078d4;
  }

  .signup-button {
    width: 100%;
    background-color: #0078d4;
    color: white;
    padding: 25px 20px;
    border: none;
    border-radius: 50px;
    margin-top: 50px;
    cursor: pointer;
    font-family: "Acme", sans-serif;
    font-size: 18px;
    font-weight: bold;
  }

  .signup-button:hover {
    background-color: #005daf;
  }
</style>
