<script>
  import { afterUpdate, onMount } from "svelte";
  import { push, link } from "svelte-spa-router";
  export let params;

  let currentPage;
  $: {
    currentPage = Number(params.page);
  }

  let oldPage = Number(params.page);

  /**
   * @type {any[]}
   */
  let topArticles = [];
  /**
   * @type {any[]}
   */
  let articles = [];
  let totalPages;

  async function fetchTopData() {
    try {
      const response = await fetch(`/api/news-articles-featured`);
      if (response.ok) {
        const topArticles_data = await response.json();
        topArticles = topArticles_data;
      } else {
        console.error("Failed to fetch data from the API");
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  }

  async function fetchData() {
    try {
      const response = await fetch(`/api/news-articles?page=${currentPage}`);
      if (response.ok) {
        const articles_data = await response.json();
        articles = articles_data.results;
        totalPages = Math.ceil(articles_data.count / 9);
      } else {
        console.error("Failed to fetch data from the API");
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  }

  function formatDate(dateString) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  }

  function prevPage() {
    if (currentPage > 1) {
      currentPage -= 1;
    }
  }

  function nextPage() {
    if (currentPage < totalPages) {
      currentPage += 1;
    }
  }

  function goToPage(newPage) {
    push(`/news/latest/${newPage}`);
  }

  let searchText = "";

  function handleSearch() {
    if (searchText) {
      push(`/news/search/${searchText}/1`);
    }
  }

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      handleSearch();
    }
  }

  onMount(() => {
    fetchTopData();
    fetchData();
  });

  afterUpdate(() => {
    if (oldPage != currentPage) {
      oldPage = currentPage;
      fetchData();
    }
  });
</script>

<!-- svelte-ignore a11y-missing-attribute -->
<slot>
  <div class="colored-rectangle">
    <div class="section-title">TOP ARTICLES</div>
    <div class="article-grid">
      {#each topArticles as topArticle, i}
        {#if i < 3}
          <a
            class="article-link"
            href="/news/article/{topArticle.slug}"
            use:link
          >
            <div class="article-rectangle">
              <img src={topArticle.thumbnail} />
              <div class="article-details">
                <div class="category {topArticle.category}">
                  {topArticle.category}
                </div>
                <h5 class="published-date">
                  {formatDate(topArticle.published_date)}
                </h5>
              </div>
              <h3 class="article-headers">{topArticle.title}</h3>
              <p class="excerpt">{topArticle.excerpt}</p>
            </div>
          </a>
        {/if}
      {/each}
    </div>
    <div class="row">
      <div class="row-container" />
      <div class="row-container">
        <div class="section-title">ALL ARTICLES</div>
      </div>
      <div class="row-container">
        <input
          type="text"
          class="search-bar"
          placeholder="Search..."
          bind:value={searchText}
          on:keydown={handleKeyPress}
        />
        <button on:click={handleSearch} class="filter-button">Go</button>
      </div>
    </div>
    <div class="article-grid">
      {#each articles as article}
        <a class="article-link" href="/news/article/{article.slug}" use:link>
          <div class="article-rectangle">
            <img src={article.thumbnail} />
            <div class="article-details">
              <div class="category {article.category}">{article.category}</div>
              <h5 class="published-date">
                {formatDate(article.published_date)}
              </h5>
            </div>
            <h3 class="article-headers">{article.title}</h3>
            <p class="excerpt">{article.excerpt}</p>
          </div>
        </a>
      {/each}
    </div>
  </div>
  <div class="pagination">
    {#if currentPage > 1}
      <button class="prev-page page-button" on:click={prevPage}>Previous</button
      >
    {/if}
    {#if currentPage > 2}
      <button class="page-button" on:click={() => goToPage(1)}>1</button>
    {/if}
    {#if currentPage > 3}
      <span class="ellipsis">...</span>
    {/if}

    {#if currentPage > 1}
      <button class="page-button" on:click={() => goToPage(currentPage - 1)}
        >{currentPage - 1}</button
      >
    {/if}

    <button class="current-button">{currentPage}</button>

    {#if currentPage < totalPages}
      <button class="page-button" on:click={() => goToPage(currentPage + 1)}
        >{currentPage + 1}</button
      >
    {/if}

    {#if currentPage < totalPages - 2}
      <span class="ellipsis">...</span>
    {/if}
    {#if currentPage < totalPages - 1}
      <button class="page-button" on:click={() => goToPage(totalPages)}
        >{totalPages}</button
      >
    {/if}
    {#if currentPage < totalPages}
      <button class="next-page page-button" on:click={nextPage}>Next</button>
    {/if}
  </div>
</slot>

<style>
  .colored-rectangle {
    width: 75%;
    margin: 100px auto;
    padding: 20px;
    background-color: #d7b594;
    border: 20px solid #bc9760;
    min-height: 80%;
    margin-top: 100px;
    border-radius: 15px;
    font-family: "Acme", sans-serif;
  }

  .row {
    display: flex;
    margin-top: 50px;
    margin-bottom: 10px;
    align-items: center;
  }

  .row-container {
    width: 33.33%;
  }

  .section-title {
    font-family: "Acme", sans-serif;
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
  }

  .search-bar {
    width: 110px;
    padding: 10px;
    margin-left: 50px;
    border: 1px solid #000;
    text-align: center;
  }

  .filter-button {
    background-color: #d7b594;
    color: #000;
    border: 1px solid #000;
    padding: 10px 20px;
    margin-left: 10px;
    cursor: pointer;
  }

  .article-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  .article-link {
    text-decoration: none;
  }

  .article-rectangle {
    width: 300px;
    height: 280px;
    background-color: #dee4e57e;
    color: #000;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5%;
    box-shadow: 7px 7px 0px rgba(52, 36, 4, 0.603);
    padding: 10px;
  }

  .article-rectangle img {
    width: 280px;
    height: 125px;
    margin-left: 10px;
    margin-top: 10px;
    border-radius: 10%;
    box-shadow: -1px -1px 0px rgb(0, 0, 0);
    background-color: white;
  }

  .article-details {
    display: flex;
    align-items: center;
    margin-left: 5px;
  }

  .category {
    background-color: #d9534f;
    color: #fff;
    border-radius: 10px;
    padding: 7px;
    min-width: 60px;
    text-transform: uppercase;
    font-size: 14px;
    text-align: center;
    font-family: "Acme", sans-serif;
    font-weight: bold;
  }

  .world {
    background-color: rgb(102, 87, 0);
  }

  .lore {
    background-color: rgb(1, 41, 114);
  }

  .games {
    background-color: rgb(0, 71, 0);
  }

  .streams {
    background-color: rgb(91, 91, 91);
  }

  .novels {
    background-color: purple;
  }

  .personal {
    background-color: rgb(123, 0, 0);
  }

  .published-date {
    font-weight: bold;
    font-size: 14px;
    margin-left: 15px;
  }

  .article-headers {
    font-weight: bold;
    margin: 0px;
    margin-left: 10px;
  }

  .excerpt {
    font-size: 14px;
    margin-top: 5px;
    margin-left: 10px;
  }

  .pagination {
    margin-top: 50px;
    text-align: center;
  }

  .page-button {
    padding: 10px 20px;
    margin: 0 7px;
    background-color: white;
    color: #000;
    border: none;
    border-radius: 15px;
    transition:
      background-color 0.3s ease,
      color 0.3s ease;
    cursor: pointer;
  }

  .current-button {
    padding: 10px 20px;
    margin: 0 7px;
    background-color: blueviolet;
    color: #fff;
    border: 1px;
    border-radius: 15px;
    transition:
      background-color 0.3s ease,
      color 0.3s ease;
    cursor: pointer;
  }

  .pagination button:hover {
    background-color: black;
    color: white;
  }

  .ellipsis {
    color: white;
  }

  @media (max-width: 1050px) {
    .search-bar {
      width: 90px;
      padding: 10px;
      margin-left: 50px;
    }

    .filter-button {
      padding: 10px 20px;
      margin-left: 10px;
    }
  }

  @media (max-width: 950px) {
    .search-bar {
      width: 90px;
      padding: 10px;
      margin-left: 30px;
    }

    .filter-button {
      padding: 10px 20px;
      margin-left: 10px;
    }
  }

  @media (max-width: 900px) {
    .search-bar {
      width: 80px;
      padding: 10px;
      margin-left: 10px;
    }

    .filter-button {
      padding: 10px 20px;
      margin-left: 5px;
    }
  }

  @media (max-width: 768px) {
    .search-bar {
      width: 70px;
      padding: 10px;
      margin-left: 10px;
    }

    .filter-button {
      padding: 10px 10px;
      margin-left: 0px;
    }
  }

  @media (max-width: 568px) {
    .article-grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 10px;
    }
    .article-rectangle {
      width: 250px;
    }
    .article-rectangle img {
      width: 230px;
    }
    .row {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .search-bar {
      width: 30px;
      padding: 10px;
      margin-left: 0px;
    }

    .filter-button {
      padding: 10px 5px;
      margin-left: 0px;
    }
  }
</style>
