<slot>
  <div class="games-container">
    <div class="pdf-games">
      <h2>PDF Games</h2>
      <div class="map-page">
        <iframe
          title="Alterra: Guildhall Trials Adventure"
          frameborder="0"
          src="https://itch.io/embed/2569218"
          width="552"
          height="167"
          ><a
            href="https://creashio.itch.io/alterra-5e-adventure-the-adventurers-guildhall-trial"
            >Alterra 5e Adventure: The Adventurers Guildhall Trial by Creashio</a
          ></iframe
        >
      </div>
    </div>

    <div class="map-explorer-games">
      <h2>Map Explorer Games</h2>
      <div class="map-page">
        <iframe
          title="Alterra: Dawn Riser Explorer Game"
          frameborder="0"
          src="https://itch.io/embed/2571786"
          width="552"
          height="167"
          ><a href="https://creashio.itch.io/alterra-dawn-riser-explorer"
            >Alterra: Dawn Riser Explorer by Creashio</a
          ></iframe
        >
      </div>
    </div>
  </div>
</slot>

<style>
  .games-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    color: white;
    margin-top: 120px;
    margin-right: 20px; /* Adjust margin for smaller screens */
    padding: 0 20px; /* Add padding for better spacing on smaller screens */
    box-sizing: border-box; /* Ensure padding is included in width */
  }

  .pdf-games,
  .map-explorer-games {
    width: 100%;
    max-width: 800px; /* Adjust max-width as needed */
    padding: 0 10px; /* Add padding for better spacing */
    box-sizing: border-box; /* Ensure padding is included in width */
  }

  .map-page iframe {
    width: 100%;
    height: auto;
  }

  h2 {
    font-size: 24px; /* Adjust font size for better readability on smaller screens */
    text-align: center; /* Center align headings */
    margin: 10px 0;
  }
</style>
